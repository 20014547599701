<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/register">Register</router-link> |
    <router-link to="/dashboard">Dashboard</router-link> |
    <button @click="logout">Logout</button>
  </div>
  <router-view />
</template>

<style>
  #app {
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
  }
</style>

<script>
import { getAuth, signOut } from "firebase/auth";
export default {
  name: "Logout",
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          console.log("Signed out");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
        });
    },
  },
};
</script>
